import Vue from "vue"
import Products from '@/components/widgets/products'
import SortedTablePlugin from "vue-sorted-table"
const _ = require('lodash')

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Products
  },
  data() {
    return {
      opened: false,
      nettotal: 0,
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      fields: [
        { 
          key: 'code', 
          label: 'รหัส', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2'
        },
        { 
          key: 'name', 
          label: 'ชื่อสินค้า', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'unit', 
          label: 'หน่วย', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'warehouse', 
          label: 'คลัง', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'storage', 
          label: 'ที่เก็บ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2'
        },
        { 
          key: 'price', 
          label: 'ราคา/หน่วย', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right'
        },
        { 
          key: 'qty', 
          label: 'จำนวน', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right'
        },
        { 
          key: 'total', 
          label: 'ยอดรวม', 
          sortable: true, 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right'
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2 text-right'
        }
      ],
      items: [],
      warehouse: null,
      warehouses: [
        { text: 'คลัง 1', value: 'warehouse1' },
        { text: 'คลัง 2', value: 'warehouse2' }
      ],
      store: null,
      storage: [
        { text: 'ที่เก็บ 1', value: 'new-storage1' },
        { text: 'ที่เก็บ 2', value: 'new-storage2' }
      ],
      newStorage: null,
      newStorages: [
        { text: 'ที่เก็บ 1', value: 'new-storage1' },
        { text: 'ที่เก็บ 2', value: 'new-storage2' }
      ]
    }
  },
  methods: {
    ondeleted(id) {
      const index = _.findIndex(this.items, function(o) { return o.uuid == id })
      this.items.splice(index, 1)
    },
    addProduct() {
      this.opened = true
    },
    onInitProducts(data) {
      this.opened = false
      this.firstOrupdate(data.data)
    },
    firstOrupdate(data) {
      if (data) {
        if(this.items.length === 0) {
          this.items = data
        } else {
          for (let index = 0; index < data.length; index++) {
            const element = data[index]
            const product = _.find(this.items, { uuid: element.uuid })
            if (!product) {
              this.items.push(element)
            }
          }
        }
      }
    },
    onSubmitForm() {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
          `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          showCancelButton: false,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          showCloseButton: true,
      })
    },
    onInitData() {
      this.nettotal = 0
      const items = this.items
      for (let index = 0; index < items.length; index++) {
        const element = items[index]
        element.total = (element.qty * element.price)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData()
    })
  }
}
